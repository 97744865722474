<template>
  <v-snackbar
    v-model="snackbar.visible"
    :timeout="snackbar.timeout"
    :multi-line="snackbar.multiline === true"
    :color="snackbar.color"
    :tile="snackbar.tile"
    :centered="true"
    rounded="pill"
    top
    right
  >
    <span class="font-weight-bold">{{ snackbar.text }}</span>
  </v-snackbar>
</template>
<script>
import { mapActions } from "vuex";
export default {
  computed: {
    snackbar() {
      return this.$store.getters.getSnackBar;
    },
  },
  methods: {
    ...mapActions(["closeToast"]),
  },
};
</script>
