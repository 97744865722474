import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00ACC1", //00E676 0097A7
        secondary: "#00897B",
        accent: "#1DE9B6",
        // error: "#FF5252",
        // info: "#2196F3",
        success: "#00BFA5",
        warning: "#FFA000",
      },
    },
  },
});
