import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Service from "./services/general";

Vue.use(Vuex);
//mapboxgl.accessToken = Service.getTokenMap();

export default new Vuex.Store({
  state: {
    status: "",
    token: sessionStorage.getItem("token") || "",
    snackbar: {
      visible: false,
      color: "success",
      text: "",
      timeout: 2500,
      multiline: false,
    },
    results: [],
  },
  mutations: {
    authSuccess(state, token) {
      state.token = token;
      state.status = "success";
    },
    authError(state) {
      state.token = "";
      state.status = "error";
      Service.removeToken();
      Service.removeUser();
    },
    authLogout(state) {
      state.token = "";
      state.status = "";
      Service.removeToken();
      Service.removeUser();
      Service.removeMenus();
    },
    openToast(state, payload) {
      state.snackbar.text = payload.text;
      state.snackbar.multiline = payload.text
        ? payload.text.length > 50
        : false;
      if (payload.multiline) {
        state.snackbar.multiline = payload.multiline;
      }
      if (payload.color) {
        state.snackbar.color = payload.color;
      }
      if (payload.timeout) {
        state.snackbar.timeout = payload.timeout;
      }
      state.snackbar.visible = true;
    },
    closeToast(state) {
      state.snackbar.visible = false;
      state.snackbar.multiline = false;
      state.snackbar.text = null;
    },
    /* SOCKET_GET_SEGUIMIENTO(state, response) {
      state.results = response;
    }, */
  },
  actions: {
    login({ commit }, ldata) {
      return new Promise((resolve, reject) => {
        axios
          .post(Service.getBase() + "usuario/auth", ldata)
          .then((response) => {
            let token = response.data.token;
            Service.setToken(token);
            if (response.data.iro == 0) {
              commit("authError", "Error en autenticación");
              reject({
                status: "error",
                msg: "Usuario no autorizado",
              });
            } else {
              Service.setUser(response.data);
              Service.setMenus(response.data.iro);
              commit("authSuccess", token);
              resolve({ status: "success", msg: "" });
            }
            // axios.defaults.headers.common['Authorization'] = "Bearer " + token;
          })
          .catch(() => {
            commit("authError", "Error en autenticación");
            reject({ status: "error", msg: "Usuario o Contraseña incorrecto" });
          });
      });
    },
    logout({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit("authLogout");
        resolve();
      });
    },
    toast(context, payload) {
      context.commit("openToast", payload);
    },
    closeToast(context) {
      context.commit("closeToast");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
    getSnackBar: (state) => state.snackbar,
    results: (state) => state.results,
  },
});
