import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Highcharts from "highcharts";
// import highchartsMore from "highcharts/highcharts-more";
import exportingInit from "highcharts/modules/exporting";
import HighchartsVue from "highcharts-vue";

//import Service from "./services/general";
//import VueSocketIO from "vue-socket.io";

import router from "./router";
import store from "./store";

/* Vue.use(
  new VueSocketIO({
    debug: false,
    connection: Service.getServe(),
    vuex: {
      store,
      actionPrefix: "socket_",
      mutationPrefix: "SOCKET_",
    },
  })
); */

exportingInit(Highcharts);
// highchartsMore(Highcharts);
Vue.use(HighchartsVue);

Vue.mixin({
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isText: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode == 32 ||
        charCode == 209 ||
        charCode == 241 ||
        (65 <= charCode && charCode <= 90) ||
        (97 <= charCode && charCode <= 122)
      ) {
        return true;
      } else {
        evt.preventDefault();
        return false;
      }
    },
    /* drowMap(cordy, cordx, centro) {
      let map = new mapboxgl.Map({
        container: 'lmap',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [cordy, cordx],
        zoom: 15
      });
      map.addControl(new mapboxgl.NavigationControl());
      map.doubleClickZoom.disable();
      let popup = new mapboxgl.Popup({ offset: 33 })
      .setText(centro);
      new mapboxgl.Marker()
        .setLngLat([cordy, cordx])
        .setPopup(popup)
        .addTo(map);
    } */
  },
});

Vue.filter("formatdate", function(value) {
  if (!value) return "";
  return value
    .substring(0, 10)
    .split("-")
    .reverse()
    .join("/");
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
