import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "../store.js";

let paths = [
  {
    path: "*",
    meta: {
      public: true,
    },
    redirect: {
      path: "/404",
    },
  },
  {
    path: "/",
    meta: {
      public: true,
      title: "Autenticación",
    },
    name: "login",
    component: () => import(`@/views/Login.vue`),
  },
  {
    path: "/404",
    meta: {
      public: false,
    },
    name: "NotFound",
    component: () => import(`@/views/NotFound.vue`),
  },
  {
    path: "/inicio",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "dashboard",
    component: () => import(`@/views/Dashboard.vue`),
  },
  {
    path: "/panel/informativo",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "panel-informativo",
    component: () => import(`@/views/PanelInformativo.vue`),
  },
  {
    path: "/usuarios",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "users",
    component: () => import(`@/views/Usuarios.vue`),
  },
  {
    path: "/perfil",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "profile",
    component: () => import(`@/views/Perfil.vue`),
  },
  /* {
    path: "/registro/atencion",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "registry",
    component: () => import(`@/views/RegistroAtencion.vue`),
  }, */
  {
    path: "/evento/noticia",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "evento-noticia",
    component: () => import(`@/views/EventoNoticia.vue`),
  },
  {
    path: "/institucion",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "institucion",
    component: () => import(`@/views/Institucion.vue`),
  },
  {
    path: "/institucion/gestion",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "institucion-gestion",
    component: () => import(`@/views/InstitucionAdmin.vue`),
  },
  {
    path: "/especialidad",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "especialidad",
    component: () => import(`@/views/Especialidad.vue`),
  },
  {
    path: "/institucion/especialidad",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "institucion-especialidad",
    component: () => import(`@/views/InstitucionEspecialidad.vue`),
  },
  {
    path: "/normativas",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "normative",
    component: () => import(`@/views/Regulation.vue`),
  },
  {
    path: "/tipo/normativa",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "normative-type",
    component: () => import(`@/views/TypeRegulation.vue`),
  },
  {
    path: "/roles",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "role",
    component: () => import(`@/views/Role.vue`),
  },
  {
    path: "/tipo/roles",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "role-type",
    component: () => import(`@/views/RoleType.vue`),
  },
  {
    path: "/estados",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "status",
    component: () => import(`@/views/Status.vue`),
  },
  {
    path: "/tipo/estados",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "status-type",
    component: () => import(`@/views/StatusType.vue`),
  },
  /* {
    path: "/generar/correlativo",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "genera-correlativo",
    component: () => import(`@/views/GenerarCorrelativo.vue`),
  },
  {
    path: "/buscar/documento",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "buscar-documento",
    component: () => import(`@/views/BuscarDocumento.vue`),
  }, */
  {
    path: "/correlativo/seguimiento",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "correlativo-seguimiento",
    component: () => import(`@/views/CorrelativoSeguimiento.vue`),
  },
  /* {
    path: "/adjuntar/documento",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "adjuntar-documento",
    component: () => import(`@/views/GenerarCorrelativo.vue`),
  }, */
  /* {
    path: "/bandeja/hojaruta",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "bandeja-hojaruta",
    component: () => import(`@/views/BandejaHojaRuta.vue`),
  }, */
  {
    path: "/reporte/tramite",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "reporte-tramite",
    component: () => import(`@/views/ReporteTramite.vue`),
  },
  {
    path: "/reporte/operador",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "report-operador",
    component: () => import(`@/views/ReporteOperador.vue`),
  },
  {
    path: "/reporte/plataforma",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "reporte-plataforma",
    component: () => import(`@/views/ReportePlataforma.vue`),
  },
  {
    path: "/reporte/tiempo",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "reporte-tiempo",
    component: () => import(`@/views/ReporteTiempo.vue`),
  },
  {
    path: "/reporte/operador/tiempo",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "reporte-operador-tiempo",
    component: () => import(`@/views/ReporteOperadorTiempo.vue`),
  },
  /* {
    path: "/tramite/realizado",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "tramite-realizado",
    component: () => import(`@/views/ReporteTramiteRealizado.vue`),
  },
  {
    path: "/tramite/realizados",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "tramite-realizados",
    component: () => import(`@/views/ReporteTramiteRealizados.vue`),
  }, */
  {
    path: "/reporte/seguimiento",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "reporte-seguimiento",
    component: () => import(`@/views/Seguimiento.vue`),
  },
  {
    path: "/seguimiento/tiempo/real",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "seguimiento-tiempo-real",
    component: () => import(`@/views/SeguimientoAlVivo.vue`),
  },
];
Vue.use(Router);
const router = new Router({
  base: "/",
  mode: "hash",
  linkActiveClass: "active",
  routes: paths,
});

router.afterEach((to) => {
  NProgress.done();
  const DEFAULT_TITLE = "Gestión DGFM";
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});
// const openRoutes=['login'];
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/autenticacion");
  } else {
    next();
  }
  /* if(openRoutes.includes(to.name)) {
    next()
  } else if (store.getters.isAuthenticated){
    next()
  } else {
    next('/autenticacion')
  } */
});

export default router;
