<template>
  <v-app-bar color="red accent-2" class="toolbar-boxes" fixed dark app>
    <v-app-bar-nav-icon @click.stop="drawerToggle"></v-app-bar-nav-icon>
    <v-toolbar-title class="body-2">
      {{ nombre }} <br />
      <span class="subheading"
        ><b class="subtitle-2">{{ rol }}</b></span
      >
    </v-toolbar-title>
    <!-- <v-toolbar-sub-title>DGFM</v-toolbar-sub-title> -->
    <v-spacer></v-spacer>
    <v-btn icon @click="fullScreen()">
      <v-icon>{{ icon_fs }}</v-icon>
    </v-btn>
    <v-menu
      offset-y
      origin="center center"
      :nudge-bottom="10"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-avatar size="42px">
            <img
              v-if="role == 1"
              v-bind:src="require('../assets/admin.svg')"
              alt="Administrador"
            />
            <img
              v-else-if="role == 2 || role == 3"
              v-bind:src="require('../assets/inspector.svg')"
              alt="Operador"
            />
            <img
              v-else
              v-bind:src="require('../assets/operator.svg')"
              alt="User"
            />
          </v-avatar>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-subheader>Opciones</v-subheader>
        <v-divider></v-divider>
        <v-list-item @click="perfil()" ripple="ripple" rel="noopener">
          <v-list-item-icon>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="logout()" ripple="ripple" rel="noopener">
          <v-list-item-icon>
            <v-icon color="error">mdi-close</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import Service from "../services/general";
export default {
  name: "toolbar",
  data: () => ({
    icon_fs: "mdi-fullscreen",
    nombre: "",
    rol: "",
    role: 0,
  }),
  mounted() {
    if (Service.getUser()) {
      this.nombre = Service.getUser().name;
      this.rol = Service.getUser().rol;
      this.role = Service.getUser().role;
    }
  },
  methods: {
    drawerToggle() {
      window.getApp.$emit("DRAWER_TOGGLE");
    },
    fullScreen() {
      let doc = window.document;
      let docEl = doc.documentElement;

      let requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen;
      let cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen;

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        requestFullScreen.call(docEl);
        this.icon_fs = "mdi-fullscreen-exit";
      } else {
        cancelFullScreen.call(doc);
        this.icon_fs = "mdi-fullscreen";
      }
    },

    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },

    perfil() {
      this.$router.push({ name: "profile" });
    },
  },
};
</script>
